export interface User {
	id: number
	name: string
	email: string
	workplaceId: number
}

export type State = {
	isLoading: boolean
	data: User
}

export enum ActionTypes {
	LOAD_START = 'users/load_start',
	LOAD_SUCCESS = 'users/load_success',
	LOAD_FAIL = 'users/load_fail'
}

interface StartAction {
	type: ActionTypes.LOAD_START
}
interface SuccessAction {
	type: ActionTypes.LOAD_SUCCESS
	payload: User
}
interface FailAction {
	type: ActionTypes.LOAD_FAIL
}

export type Actions = StartAction | SuccessAction | FailAction
