import authService, { AuthService } from './auth.service'
import usersService, { UsersService } from './users.service'
import workdaysService, { WorkdaysService } from './workdays.service'
import workplacesService, { WorkplacesService } from './workplaces.service'
import paymentsService, { PaymentsService } from './payments.service'
import schedulesService, { SchedulesService } from './schedules.service'

export interface Services {
	auth: AuthService
	users: UsersService
	workdays: WorkdaysService
	workplaces: WorkplacesService
	schedules: SchedulesService
	payments: PaymentsService
}

const services: Services = {
	auth: authService,
	users: usersService,
	workdays: workdaysService,
	workplaces: workplacesService,
	payments: paymentsService,
	schedules: schedulesService
}

export default services
