import { Actions } from '../types'
import { ActionTypes, AddNotificationPayload } from './types'

export const add = (payload: AddNotificationPayload): Actions => ({
	type: ActionTypes.ADD,
	payload
})

export const remove = (id: string): Actions => ({
	type: ActionTypes.REMOVE,
	payload: { id }
})
