export interface Payment {
	id: number
	day: string
	amount: number
}

export interface Payments {
	[date: string]: Payment
}

export interface State {
	// Data
	isLoading: boolean
	data: Payments
}

export enum ActionTypes {
	RESET = 'payment-validate/reset',
	LOAD_START = 'payment-validate/load_start',
	LOAD_SUCCESS = 'payment-validate/load_success',
	LOAD_FAIL = 'payment-validate/load_fail'
}

interface ResetAction {
	type: ActionTypes.RESET
}

interface StartAction {
	type: ActionTypes.LOAD_START
}

interface SuccessAction {
	type: ActionTypes.LOAD_SUCCESS
	payload: Payments
}

interface FailAction {
	type: ActionTypes.LOAD_FAIL
}

export type Actions = ResetAction | StartAction | SuccessAction | FailAction
