import { startCase } from 'lodash'
import moment from 'moment'
import React, { useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from '~/hooks'

import {
	actions as paymentsActions,
	selectors as paymentsSelectors
} from '~/redux/payments/validate'
import { Payment } from '~/redux/payments/validate/types'

const Payments: React.FC = () => {
	// State
	const payments = useSelector(paymentsSelectors.getPayments)

	// Dispatch
	const dispatch = useDispatch()

	// Handlers
	const handleValidate = useCallback(
		(payment: Payment) => {
			dispatch(paymentsActions.validatePaymentsAsync(payment.id))
		},
		[dispatch]
	)

	// Lifecycle
	useEffect(() => {
		dispatch(paymentsActions.nonValidatePaymentsAsync())
	}, [dispatch])

	return (
		<div className="h-full flex flex-col justify-center">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 border px-2 py-4 transition-all duration-500">
					{Object.values(payments).length === 0 && (
						<span>Sem pagamentos por validar</span>
					)}
					{Object.values(payments).map(p => (
						<div
							key={`payment-${p.id}`}
							className={`flex flex-col gap-4`}
						>
							<div className="flex justify-between">
								<h1>
									Pagamento no dia:{' '}
									<b>
										{startCase(
											moment(p.day).format('DD MMMM YYYY')
										)}
									</b>
								</h1>
							</div>
							<div className="flex justify-start gap-2">
								<span>Valor:</span>
								<b>{p.amount}€</b>
							</div>
							<div className="flex justify-end">
								<button
									className="px-4 py-2 rounded bg-green-500 text-gray-100 hover:bg-green-600"
									onClick={() => handleValidate(p)}
								>
									Validar
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Payments
