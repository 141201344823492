import React, { useCallback, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from '~/hooks'

import { actions as authActions } from '~/redux/auth'
import { actions as notificationsActions } from '~/redux/notifications'
import { NotificationType } from '~/redux/notifications/types'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const ResetPassword: React.FC = () => {
	const { token } = useParams<{ token: string }>()
	const query = useQuery()
	const [email, setEmail] = useState(query.get('email') || '')
	const [password, setPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')

	const history = useHistory()
	const dispatch = useDispatch()

	const handleLogin: React.FormEventHandler<HTMLFormElement> = useCallback(
		e => {
			e.preventDefault()
			dispatch(
				authActions.resetPassword({
					token,
					email,
					password,
					passwordConfirmation
				})
			).then(res => {
				dispatch(
					notificationsActions.add({
						type: NotificationType.SUCCESS,
						message: res?.message || 'Pedido enviado com sucesso!'
					})
				)
				history.replace('/')
			})
		},
		[dispatch, history, token, email, password, passwordConfirmation]
	)

	return (
		<div className="h-full flex flex-col justify-center">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 border px-2 py-4 transition-all duration-500">
					<h1 className="items-center text-center">Time Sheet</h1>
					<form
						className="flex flex-col gap-4"
						onSubmit={handleLogin}
					>
						<label htmlFor="email">Email:</label>
						<input
							className="block w-full mt-1 text-black"
							id="email"
							type="email"
							name="email"
							placeholder="Email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							required
						/>
						<label htmlFor="email">Password:</label>
						<input
							className="block w-full mt-1 text-black"
							id="password"
							type="password"
							name="password"
							placeholder="Password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							required
						/>
						<label htmlFor="email">Confirmação de password:</label>
						<input
							className="block w-full mt-1 text-black"
							id="passwordConfirmation"
							type="password"
							name="passwordConfirmation"
							placeholder="Confirmação de password"
							value={passwordConfirmation}
							onChange={e =>
								setPasswordConfirmation(e.target.value)
							}
							required
						/>
						<button
							className="bg-gray-300 border border-gray-400 p-2 hover:bg-indigo-500 hover:text-gray-300"
							type="submit"
						>
							Atualizar
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
