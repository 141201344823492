import axiosClient from './axios-client'

interface LoginResponse {
	twoFactor: boolean
}

interface LoginRequest {
	email: string
	password: string
}
interface ForgetPasswordRequest {
	email: string
}
interface ResetPasswordRequest {
	token: string
	email: string
	password: string
	passwordConfirmation: string
}
interface Response {
	message: string
}

export interface AuthService {
	login: (request: LoginRequest) => Promise<void>
	forgotPassword: (request: ForgetPasswordRequest) => Promise<Response>
	resetPassword: (request: ResetPasswordRequest) => Promise<Response>
	logout: () => Promise<void>
}

const authService: AuthService = {
	login: async (request: LoginRequest) =>
		await axiosClient.get('/sanctum/csrf-cookie').then(() => {
			return axiosClient
				.post<LoginResponse>('/login', request)
				.then(({ status }) => {
					if (status !== 200) {
						throw new Error(
							'Something went wrong! Please contact support.'
						)
					}
				})
		}),
	forgotPassword: async (request: ForgetPasswordRequest) =>
		await axiosClient.get('/sanctum/csrf-cookie').then(() => {
			return axiosClient
				.post<Response>('/forgot-password', request)
				.then(({ status, data }) => {
					if (status !== 200) {
						throw new Error(
							'Something went wrong! Please contact support.'
						)
					}
					return data
				})
		}),
	resetPassword: async (request: ResetPasswordRequest) =>
		await axiosClient.get('/sanctum/csrf-cookie').then(() => {
			return axiosClient
				.post<Response>('/reset-password', request)
				.then(({ status, data }) => {
					if (status !== 200) {
						throw new Error(
							'Something went wrong! Please contact support.'
						)
					}
					return data
				})
		}),
	logout: async () =>
		await axiosClient.post('/logout').then(({ status }) => {
			if (status !== 204) {
				throw new Error('Something went wrong! Please contact support.')
			}
		})
}

export default authService
