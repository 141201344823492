import axiosClient from './axios-client'

interface WorkplaceResponse {
	id: number
	name: string
}

interface ShiftResponse {
	id: number
	startedAt: string
	endedAt: string
	workplace: WorkplaceResponse
}
interface ShiftRequest {
	id?: number
	startedAt: string
	endedAt: string
	workplaceId: number
}

export interface WorkdayResponse {
	id: number
	day: string
	dayOff: boolean
	sickLeave: boolean
	absence: boolean
	validated: boolean
	shifts: ShiftResponse[]
}

export interface CreateWorkdayRequest {
	day: string
	dayOff: boolean
	shifts: ShiftRequest[]
}

export interface EditWorkdayRequest {
	id: number
	dayOff: boolean
	shifts: ShiftRequest[]
}

export interface WorkdaysResponse {
	today: string
	firstDay: string
	lastDay: string
	workdays: WorkdayResponse[]
}

export interface WorkdaysService {
	get: (year: number, month: number) => Promise<WorkdaysResponse>
	post: (workday: CreateWorkdayRequest) => Promise<WorkdaysResponse>
	put: (workday: EditWorkdayRequest) => Promise<WorkdaysResponse>
}

const workdaysService: WorkdaysService = {
	get: async (year: number, month: number) =>
		await axiosClient
			.get<WorkdaysResponse>(`/api/workdays/year/${year}/month/${month}`)
			.then(response => ({
				today: response.data.today,
				firstDay: response.data.firstDay,
				lastDay: response.data.lastDay,
				workdays: response.data.workdays
			})),
	post: async (workday: CreateWorkdayRequest) =>
		await axiosClient
			.post<WorkdaysResponse>(`/api/workdays`, workday)
			.then(response => ({
				today: response.data.today,
				firstDay: response.data.firstDay,
				lastDay: response.data.lastDay,
				workdays: response.data.workdays
			})),
	put: async ({ id, ...workday }: EditWorkdayRequest) =>
		await axiosClient
			.put<WorkdaysResponse>(`/api/workdays/${id}`, workday)
			.then(response => ({
				today: response.data.today,
				firstDay: response.data.firstDay,
				lastDay: response.data.lastDay,
				workdays: response.data.workdays
			}))
}

export default workdaysService
