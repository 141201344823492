import React from 'react'
import { useSelector } from '~/hooks'

import { selectors as notificationsSelectors } from '~/redux/notifications'

import Notification from './Notification'

const Notifications: React.FC = () => {
	const notifications = useSelector(notificationsSelectors.getData)

	return (
		<div className="fixed left-0 right-0 top-20 z-50">
			<div className="container mx-auto md:max-w-3xl p-4 z-10 flex flex-col justify-end items-end gap-2">
				{notifications.map(n => (
					<Notification key={n.id} {...n} />
				))}
			</div>
		</div>
	)
}

export default Notifications
