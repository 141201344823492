import React, { Fragment } from 'react'

type Props = {
	label: string
}

const TabContent: React.FC<Props> = ({ children }) => {
	return <Fragment>{children}</Fragment>
}

export default TabContent
