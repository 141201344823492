import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from './use-redux'

import { AsyncAction, PSelector, Selector, State } from '~/redux/types'

function shouldLoadData<D, E>(
	selectorData: Selector<D> | PSelector<E, D>,
	selectorLoading: Selector<boolean> | PSelector<E, boolean>,
	shouldToLoadData: (extraArgs: E) => boolean,
	extraArgs: E
): Selector<boolean> {
	return state =>
		!selectorLoading(state, extraArgs) &&
		isEmpty(selectorData(state, extraArgs)) &&
		shouldToLoadData(extraArgs)
}

function ensureAction<D, A, E>(
	selectorIsLoading: Selector<boolean> | PSelector<E, boolean>,
	selectorData: Selector<D> | PSelector<E, D>,
	fetchAction: AsyncAction<A>,
	shouldToLoadData: (extraArgs: E) => boolean,
	extraArgs: E
): AsyncAction<boolean> {
	return async (dispatch, getState, extraArguments) => {
		if (
			shouldLoadData(
				selectorData,
				selectorIsLoading,
				shouldToLoadData,
				extraArgs
			)(getState())
		) {
			await fetchAction(dispatch, getState, extraArguments)
			return true
		} else {
			return false
		}
	}
}
const shouldToLoadDataDefault = () => true
const defaultMaximumFetches = 1
const defaultExtraArgs = {}
function useEnsure<D, A, E>(
	selectorIsLoading: Selector<boolean> | PSelector<E, boolean>,
	selectorData: Selector<D> | PSelector<E, D>,
	fetchAction: (extraArgs: E) => AsyncAction<A>,
	extraArgs: E = defaultExtraArgs as E,
	shouldToLoadData: (extraArgs: E) => boolean = shouldToLoadDataDefault,
	maximumFetches: number = defaultMaximumFetches
): [D, boolean] {
	const dispatch = useDispatch()
	const [remainingFetches, setRemainingFetches] = useState(maximumFetches)

	const handleFetchData = useCallback(async () => {
		return await dispatch(
			ensureAction(
				selectorIsLoading,
				selectorData,
				fetchAction(extraArgs),
				shouldToLoadData,
				extraArgs
			)
		).then((result: boolean) => {
			result &&
				setRemainingFetches(remainingFetches => remainingFetches - 1)
		})
	}, [
		dispatch,
		selectorData,
		selectorIsLoading,
		fetchAction,
		shouldToLoadData,
		extraArgs
	])

	useEffect(() => {
		if (remainingFetches > 0) {
			handleFetchData()
		}
	}, [handleFetchData, remainingFetches])

	const data = useSelector((state: State) => selectorData(state, extraArgs))
	const isLoading = useSelector((state: State) =>
		selectorIsLoading(state, extraArgs)
	)

	return [data, isLoading]
}

export default useEnsure
