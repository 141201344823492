interface Shift {
	id: number
	workplaceId: number
	startedAt: string
	endedAt: string
}
export type Shifts = Shift[]

export interface Workday {
	id: number
	day: string
	dayOff: boolean
	sickLeave: boolean
	absence: boolean
	shifts: Shifts
	validated: boolean
	disabled: boolean
}

export interface Workdays {
	[date: string]: Workday
}

export interface State {
	month: string
	day: string

	// Data
	isLoading: boolean
	data: Workdays
}

export enum ActionTypes {
	RESET = 'workdays/reset',
	INIT = 'workdays/init',
	CHANGE_MONTH = 'workdays/change_month',
	CHANGE_DAY = 'workdays/change_day',
	LOAD_START = 'workdays/load_start',
	LOAD_SUCCESS = 'workdays/load_success',
	LOAD_FAIL = 'workdays/load_fail'
}

interface InitAction {
	type: ActionTypes.INIT
	payload: {
		month: string
		day: string
	}
}

interface ResetAction {
	type: ActionTypes.RESET
}

interface ChangeMonthAction {
	type: ActionTypes.CHANGE_MONTH
	payload: string
}

interface ChangeDayAction {
	type: ActionTypes.CHANGE_DAY
	payload: string
}

interface StartAction {
	type: ActionTypes.LOAD_START
}

interface SuccessAction {
	type: ActionTypes.LOAD_SUCCESS
	payload: Workdays
}

interface FailAction {
	type: ActionTypes.LOAD_FAIL
}

export type Actions =
	| InitAction
	| ResetAction
	| ChangeMonthAction
	| ChangeDayAction
	| StartAction
	| SuccessAction
	| FailAction
