import {
	TypedUseSelectorHook,
	useDispatch as useReduxDispatch,
	useSelector as useReduxSelector
} from 'react-redux'
import { Dispatch, State } from '~/redux/types'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = (): Dispatch => useReduxDispatch<Dispatch>()
export const useSelector: TypedUseSelectorHook<State> = useReduxSelector
