import { get } from 'lodash'

import { State as RootState } from '~/redux/types'

import { getters } from './reducer'
import { State } from './types'

const getFromState = (state: RootState): State => get(state, 'auth')

export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
export const getLoggedIn = (state: RootState): boolean =>
	getters.loggedIn(getFromState(state))
