export interface EditableShift {
	id?: number
	workplaceId: number
	startedAt: string
	endedAt: string
}

export interface State {
	id: number
	day: string
	dayOff: boolean
	shifts: EditableShift[]
}

export enum DateTimeType {
	STARTED_AT = 'startedAt',
	ENDED_AT = 'endedAt'
}

export enum ActionTypes {
	LOAD = 'form/load',
	CHANGE_DAY_OFF = 'form/change_day_off',
	CHANGE_WORKPLACE = 'form/change_workplace',
	CHANGE_DATE = 'form/change_date',
	CREATE_SHIFT = 'form/create_shift',
	SUBMIT_START = 'form/submit_start',
	SUBMIT_SUCCESS = 'form/submit_success',
	SUBMIT_FAIL = 'form/submit_fail',
	REMOVE_SHIFT = `form/remove_shift`
}

interface LoadFormAction {
	type: ActionTypes.LOAD
	payload: State
}

interface ChangeDayOff {
	type: ActionTypes.CHANGE_DAY_OFF
	payload: boolean
}

interface ChangeWorkplace {
	type: ActionTypes.CHANGE_WORKPLACE
	payload: {
		index: number
		id: number
	}
}

interface ChangeDate {
	type: ActionTypes.CHANGE_DATE
	payload: {
		index: number
		type: DateTimeType
		date: string
	}
}

interface RemoveShift {
	type: ActionTypes.REMOVE_SHIFT
	payload: {
		index: number
	}
}

interface CreateShift {
	type: ActionTypes.CREATE_SHIFT
}

interface SubmitStartAction {
	type: ActionTypes.SUBMIT_START
}
interface SubmitSuccessAction {
	type: ActionTypes.SUBMIT_SUCCESS
}
interface SubmitFailAction {
	type: ActionTypes.SUBMIT_FAIL
}

export type Actions =
	| LoadFormAction
	| ChangeDayOff
	| ChangeWorkplace
	| ChangeDate
	| CreateShift
	| SubmitStartAction
	| SubmitSuccessAction
	| SubmitFailAction
	| RemoveShift
