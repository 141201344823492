import React, { Fragment, useCallback } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { useDispatch, useSelector } from '~/hooks'

import {
	actions as authActions,
	selectors as authSelectors
} from '~/redux/auth'
import { selectors as userSelectors } from '~/redux/user'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
	const loggedIn = useSelector(authSelectors.getLoggedIn)
	// Dispatch
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.getUser)

	const logout = useCallback(() => dispatch(authActions.logout()), [dispatch])

	return (
		<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 transition-all duration-500">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="flex justify-between items-center">
					<div className="flex-grow flex justify-start">
						{loggedIn && <h1>Time Sheet</h1>}
					</div>
					<div>
						<img
							className="h-12"
							src="/logo260.jpg"
							alt="[Jet7.5]"
						/>
					</div>
					<div className="flex-grow flex justify-end items-center">
						{/* <div className="w-12">
							{loggedIn && <button onClick={logout}>Sair</button>}
							<Switch
								onChange={toggle}
								checked={theme === Theme.DARK}
							/>
						</div> */}
						{loggedIn && (
							<Menu
								as="div"
								className="relative inline-block text-left"
							>
								<div>
									<Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
										{user?.name}
										<ChevronDownIcon
											className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
											aria-hidden="true"
										/>
									</Menu.Button>
								</div>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
										<div className="px-1 py-1 ">
											<Menu.Item>
												{({ active }) => (
													<Link
														to="/time-sheet"
														className={`${
															active
																? 'bg-indigo-500 text-white'
																: 'text-gray-900'
														} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
													>
														Time Sheet
													</Link>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<Link
														to="/schedules"
														className={`${
															active
																? 'bg-indigo-500 text-white'
																: 'text-gray-900'
														} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
													>
														Horários
													</Link>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<Link
														to="/payments"
														className={`${
															active
																? 'bg-indigo-500 text-white'
																: 'text-gray-900'
														} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
													>
														Pagamentos
													</Link>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={logout}
														className={`${
															active
																? 'bg-indigo-500 text-white'
																: 'text-gray-900'
														} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
													>
														Sair
													</button>
												)}
											</Menu.Item>
										</div>
									</Menu.Items>
								</Transition>
							</Menu>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header
