import { combineReducers } from 'redux'

import authReducer, { initialState as authInitialState } from './auth'
import notificationsReducer, {
	initialState as notificationsInitialState
} from './notifications'
import userReducer, { initialState as userInitialState } from './user'
import workdaysReducer, {
	initialState as workdaysInitialState
} from './workdays'
import workplacesReducer, {
	initialState as workplacesInitialState
} from './workplaces'
import schedulesReducer, {
	initialState as schedulesInitialState
} from './schedules'
import paymentsReducer, {
	initialState as paymentsInitialState
} from './payments'

import { Reducer, State } from './types'

export const reducer: Reducer = combineReducers({
	auth: authReducer,
	notifications: notificationsReducer,
	user: userReducer,
	schedules: schedulesReducer,
	workdays: workdaysReducer,
	workplaces: workplacesReducer,
	payments: paymentsReducer
})

export const initialState: State = {
	auth: authInitialState,
	notifications: notificationsInitialState,
	user: userInitialState,
	schedules: schedulesInitialState,
	workdays: workdaysInitialState,
	workplaces: workplacesInitialState,
	payments: paymentsInitialState
}
