import { capitalize } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'

import { Workdays } from '~/redux/workdays/data/types'

import Day from './Day'
import MoveButton from './MoveButton'
import Weekday from './Weekday'

interface Props {
	month: string
	onChangeMonth: (month: string) => void
	onChangeDate: (date: string) => void
	onEdit: (date: string) => void
	workdays: Workdays
}

const Calendar: React.FC<Props> = ({
	month,
	onChangeMonth,
	onChangeDate,
	onEdit,
	workdays
}) => {
	const handleDecrement = useCallback(() => {
		onChangeMonth(moment(month, 'Y-M').subtract(1, 'month').format('Y-M'))
	}, [onChangeMonth, month])

	const handleIncrement = useCallback(() => {
		onChangeMonth(moment(month, 'Y-M').add(1, 'month').format('Y-M'))
	}, [onChangeMonth, month])

	return (
		<div className="flex flex-col gap-2">
			<div className="grid grid-flow-row grid-cols-7 gap-0.5 justify-items-center items-center">
				<MoveButton
					onClick={handleDecrement}
					className="justify-self-start"
				>
					{'<'}
				</MoveButton>
				<p className="text-center col-span-5">
					{capitalize(moment(month, 'Y-M').format('MMMM [de] Y'))}
				</p>
				<MoveButton
					onClick={handleIncrement}
					className="justify-self-end"
				>
					{'>'}
				</MoveButton>
			</div>
			<div className="grid grid-flow-row grid-cols-7 gap-0.5">
				<Weekday>Seg</Weekday>
				<Weekday>Ter</Weekday>
				<Weekday>Qua</Weekday>
				<Weekday>Qui</Weekday>
				<Weekday>Sex</Weekday>
				<Weekday>Sab</Weekday>
				<Weekday>Dom</Weekday>
				{Object.keys(workdays).map(day => (
					<Day
						key={day}
						date={day}
						onSelect={onChangeDate}
						onEdit={onEdit}
					/>
				))}
			</div>
		</div>
	)
}

export default Calendar
