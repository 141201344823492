import { get } from 'lodash'

import { State as RootState } from '../types'

import { State, Schedules } from './types'
import { getters } from './reducer'

const getFromState = (state: RootState): State => get(state, 'schedules')

export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
export const getSchedules = (state: RootState): Schedules =>
	getters.data(getFromState(state))
