import { combineReducers, Reducer } from 'redux'
import { get } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import { Actions } from '../types'

import { ActionTypes, Notifications, State } from './types'

export const initialState: State = {
	data: []
}

const data: Reducer<Notifications, Actions> = (
	state: Notifications = initialState.data,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.ADD:
			return [...state, { id: uuidV4(), ...action.payload }]
		case ActionTypes.REMOVE:
			return state.filter(({ id }) => id !== action.payload.id)
		default:
			return state
	}
}

export const getters = {
	data: (state: State): Notifications => get(state, 'data', initialState.data)
}

export default combineReducers({
	data
})
