import moment from 'moment'
import { AsyncAction } from '~/redux/types'

import { ActionTypes, Actions, Payment } from './types'

export const reset = (): Actions => ({
	type: ActionTypes.RESET
})

export const nonValidatePaymentsAsync =
	(): AsyncAction<void> =>
	async (dispatch, getState, { payments }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const payload = await payments.getNonValidate()

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: payload.reduce<{ [date: string]: Payment }>(
					(agg, payment) => ({
						...agg,
						[moment.utc(payment.day).format()]: payment
					}),
					{}
				)
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}

export const validatePaymentsAsync =
	(id: number): AsyncAction<void> =>
	async (dispatch, getState, { payments }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const payload = await payments.validate(id)

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: payload.reduce<{ [date: string]: Payment }>(
					(agg, payment) => ({
						...agg,
						[moment.utc(payment.day).format()]: payment
					}),
					{}
				)
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}
