import { AsyncAction } from '../types'
import { ActionTypes } from './types'

export const schedulesAsync =
	(): AsyncAction<void> =>
	async (dispatch, getState, { schedules }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const payload = await schedules.getAll()

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: payload
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}
