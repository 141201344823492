import React from 'react'

interface Props {
	onClick: () => void
	className: string
}

const MoveButton: React.FC<Props> = ({ onClick, className, children }) => (
	<div className={['w-8', className].join(' ')}>
		<div className="aspect-w-1 aspect-h-1">
			<button
				className="bg-gray-200 hover:bg-gray-300 w-full h-full"
				onClick={onClick}
			>
				{children}
			</button>
		</div>
	</div>
)

export default MoveButton
