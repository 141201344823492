import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { selectors as authSelectors } from '~/redux/auth'

import Header from '~/components/header'
import { ThemeProvider } from '~/context'

import Router from './routes'
import Notifications from '~/components/notifications'

const Page: React.FC = () => {
	const loggedIn = useSelector(authSelectors.getLoggedIn)

	return (
		<ThemeProvider>
			<BrowserRouter>
				<div className="h-screen w-screen bg-white dark:bg-gray-800 transition-all duration-500 flex flex-col">
					<div className="flex-grow-0">
						<Header />
					</div>
					<div className="flex-grow relative">
						<Notifications />
						<Router loggedIn={loggedIn} />
					</div>
				</div>
			</BrowserRouter>
		</ThemeProvider>
	)
}

export default Page
