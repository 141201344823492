export enum NotificationType {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error'
}

interface Notification {
	id: string
	type: NotificationType
	title?: string
	message: string
}

export type Notifications = Notification[]

export interface State {
	data: Notifications
}

export enum ActionTypes {
	ADD = 'notifications/add',
	REMOVE = 'notifications/remove'
}

export interface AddNotificationPayload {
	type: NotificationType
	title?: string
	message: string
}
interface AddNotificationAction {
	type: ActionTypes.ADD
	payload: AddNotificationPayload
}

interface RemoveNotificationAction {
	type: ActionTypes.REMOVE
	payload: {
		id: string
	}
}

export type Actions = AddNotificationAction | RemoveNotificationAction
