import classNames from 'classnames'
import React, { useCallback } from 'react'

import { useReSelector } from '~/hooks'

import { selectors as dataWorkdaysSelectors } from '~/redux/workdays/data'
import { selectors as paymentsSelectors } from '~/redux/payments'

interface Props {
	date: string
	onSelect: (date: string) => void
	onEdit: (date: string) => void
}

const Day: React.FC<Props> = ({ date, onSelect, onEdit }) => {
	const workday = useReSelector(dataWorkdaysSelectors.getWorkday, { date })
	const payment = useReSelector(paymentsSelectors.getWorkday, { date })
	const label = workday.id ? 'Editar' : 'Inserir'
	const isSelected = useReSelector(dataWorkdaysSelectors.getIsSelectedDay, {
		date
	})
	const inMonth = useReSelector(dataWorkdaysSelectors.getDayInMonth, {
		date
	})
	const formattedDay = useReSelector(dataWorkdaysSelectors.getFormattedDay, {
		date
	})

	// Handlers
	const handleSelect = useCallback(() => {
		onSelect(date)
	}, [onSelect, date])

	const handleEdit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()
			onEdit(date)
		},
		[onEdit, date]
	)
	/**
	 *	'bg-indigo-500': workdayId != null && !validated && !disabled,
	 *	'bg-green-400': validated,
	 *	'bg-green-500': validated,
	 *	'bg-gray-400': disabled && !validated,
	 *	'bg-gray-500': disabled && !validated && !sameMonth
	 */
	/**
	 * 'bg-gray-500': !sameMonth,
	 * 'bg-green-500 cursor-not-allowed': validated,
	 * 'bg-indigo-500': sameMonth && id != null && !validated,
	 * 'bg-gray-400 cursor-not-allowed': sameMonth && disabled,
	 * 'border-red-500': isSelected,
	 * 'bg-gray-900': sameMonth && !validated && !disabled && id === null
	 */

	return (
		<div
			className={classNames(
				`w-full border border-transparent cursor-pointer justify-self-stretch`,
				{
					'bg-gray-400 text-gray-100': !inMonth,
					'bg-gray-300': inMonth && workday.disabled,
					'bg-gray-200': inMonth && !workday.disabled,
					'bg-blue-600':
						!inMonth &&
						!workday.validated &&
						(workday.shifts.length > 0 || workday.dayOff),
					'bg-blue-400':
						!workday.validated &&
						(workday.shifts.length > 0 || workday.dayOff),
					'bg-green-600':
						!inMonth &&
						workday.validated &&
						!workday.absence &&
						!workday.sickLeave,
					'bg-green-400':
						inMonth &&
						workday.validated &&
						!workday.absence &&
						!workday.sickLeave,
					'bg-red-600': !inMonth && workday.absence,
					'bg-red-400': inMonth && workday.absence,
					'bg-indigo-600': !inMonth && workday.sickLeave,
					'bg-indigo-400': inMonth && workday.sickLeave,
					'border-gray-500': isSelected
				}
			)}
			onClick={handleSelect}
		>
			<div
				className={classNames(
					'p-1 leading-4 flex flex-col justify-between',
					{}
				)}
			>
				<div className="flex justify-between items-center">
					<span>{`${formattedDay}`}</span>
					<span className="font-semibold text-xs leading-3">
						{workday.dayOff && 'F'}
						{workday.absence && 'X'}
						{workday.sickLeave && 'B'}
						{!workday.dayOff &&
							workday.hours > 0 &&
							`${workday.hours}H`}
					</span>
				</div>
				<div className="flex flex-row justify-end items-center font-semibold text-xs w-full text-right h-4">
					{payment && payment.amount && `${payment.amount}€`}
				</div>
				<div className="flex flex-row justify-end items-center font-semibold text-xs w-full text-right h-4">
					{isSelected && !workday.disabled && !workday.validated && (
						<button onClick={handleEdit}>{label}</button>
					)}
				</div>
			</div>
		</div>
	)
}

// DayOff
// Filled
// Validate
// Disabled

export default Day
