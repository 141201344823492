export { default as Time } from './time'

export const notEmpty = <TValue>(
	value: TValue | null | undefined
): value is TValue => {
	return value !== null && value !== undefined
}

export function onlyUnique<TValue>(
	value: TValue,
	index: number,
	self: TValue[]
): boolean {
	return self.indexOf(value) === index
}
