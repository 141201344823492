import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
	loggedIn: boolean
}

const PrivateRoute: React.FC<Props> = ({ children, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			rest.loggedIn ? (
				children
			) : (
				<Redirect
					to={{ pathname: '/', state: { from: props.location } }}
				/>
			)
		}
	/>
)

export default PrivateRoute
