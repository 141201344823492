import { AsyncAction } from '../types'
import { ActionTypes } from './types'

export const userAsync =
	(): AsyncAction<void> =>
	async (dispatch, getState, { users }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const { workplace, ...user } = await users.me()

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: { ...user, workplaceId: workplace.id }
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}
