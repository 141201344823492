import moment from 'moment'

interface TimeInterface {
	hour: number
	minute: number
}

export interface TimeOptions {
	[hour: number]: TimeInterface[]
}

export interface Boundary {
	max: string
	min: string
}

class Time {
	public time: string
	public baseTime: string
	public limit = 0

	constructor(time: string, baseTime?: string) {
		this.time = time
		this.baseTime = `${(baseTime || time).split('T')[0]}T00:00:00Z`
	}

	public static create(time: string, baseTime?: string): Time {
		return new Time(time, baseTime)
	}

	public setLimit(limit: number): Time {
		this.limit = limit
		return this
	}

	public setTime(hour: number, minute = 0): Time {
		this.time = moment(this.baseTime)
			.utc()
			.add(hour, 'hours')
			.add(minute, 'minutes')
			.format()
		return this
	}

	public setHour(value: number): Time {
		return Time.create(
			moment(this.baseTime)
				.utc()
				.add(value, 'hours')
				.add(moment.utc(this.time).minutes(), 'minutes')
				.format(),
			this.baseTime
		)
	}

	public setMinute(value: number): Time {
		return Time.create(
			moment(this.time).utc().minute(value).format(),
			this.baseTime
		)
	}

	private increment(hour = 0, minute = 0): Time {
		this.time = moment(this.time)
			.utc()
			.add(hour, 'hours')
			.add(minute, 'minutes')
			.format()
		return this
	}

	public setBaseDate(time: string): Time {
		this.baseTime = `${time.split('T')[0]}T00:00:00Z`
		return this
	}

	public get timeObject(): TimeInterface {
		const timeMoment = moment(this.time).utc()
		const baseTimeMoment = moment(this.baseTime).utc()
		const hour = Math.floor(
			moment.duration(timeMoment.diff(baseTimeMoment)).asHours()
		)
		return {
			hour: this.limit ? Math.min(this.limit, hour) : hour,
			minute: timeMoment.minute()
		}
	}

	public static getOptions(min: string, max: string): TimeOptions {
		const options: TimeOptions = {}
		for (
			let iterable = Time.create(min);
			iterable <= Time.create(max);
			iterable.increment(0, 30)
		) {
			const time = iterable.timeObject
			options[time.hour] = options[time.hour] ?? []
			options[time.hour].push(time)
		}
		return options
	}

	public static max(a: string, b: string): string {
		return moment.max(moment.utc(a), moment.utc(b)).format()
	}
}

Time.prototype.valueOf = function (): number {
	return moment(this.time).utc().unix()
}

export default Time
