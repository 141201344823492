import {
	Actions as ValidateActions,
	State as ValidateState
} from './validate/types'

export interface Payment {
	id: number
	day: string
	amount: number
}

export interface Payments {
	[date: string]: Payment
}

export interface State {
	// Data
	isLoading: boolean
	data: Payments
	validate: ValidateState
}

export enum ActionTypes {
	RESET = 'payment/reset',
	LOAD_START = 'payment/load_start',
	LOAD_SUCCESS = 'payment/load_success',
	LOAD_FAIL = 'payment/load_fail'
}

interface ResetAction {
	type: ActionTypes.RESET
}

interface StartAction {
	type: ActionTypes.LOAD_START
}

interface SuccessAction {
	type: ActionTypes.LOAD_SUCCESS
	payload: Payments
}

interface FailAction {
	type: ActionTypes.LOAD_FAIL
}

export type Actions =
	| ResetAction
	| StartAction
	| SuccessAction
	| FailAction
	| ValidateActions
