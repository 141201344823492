import { AsyncAction } from '../types'
import { ActionTypes } from './types'

export const workplacesAsync =
	(): AsyncAction<void> =>
	async (dispatch, getState, { workplaces }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const payload = await workplaces.getAll()

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: payload.map(w => ({
					id: w.id,
					name: w.name
				}))
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}
