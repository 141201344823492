import { combineReducers, Reducer } from 'redux'
import { get } from 'lodash'

import { Actions } from '../types'

import { ActionTypes, State, Workdays } from './types'
import moment from 'moment'

export const initialState: State = {
	month: '',
	day: '',

	// Data
	isLoading: false,
	data: {}
}

const month: Reducer<string, Actions> = (
	state: string = initialState.month,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.INIT:
			return action.payload.month
		case ActionTypes.CHANGE_MONTH:
			return action.payload
		case ActionTypes.CHANGE_DAY:
			return moment(action.payload).format('Y-M')
		case ActionTypes.RESET:
			return initialState.month
		default:
			return state
	}
}

const day: Reducer<string, Actions> = (
	state: string = initialState.day,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.INIT:
			return action.payload.day
		case ActionTypes.CHANGE_DAY:
			return action.payload
		case ActionTypes.RESET:
			return initialState.day
		default:
			return state
	}
}

const isLoading: Reducer<boolean, Actions> = (
	state: boolean = initialState.isLoading,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD_START:
			return true
		case ActionTypes.LOAD_SUCCESS:
		case ActionTypes.LOAD_FAIL:
			return false
		default:
			return state
	}
}

const data: Reducer<Workdays, Actions> = (
	state: Workdays = initialState.data,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD_SUCCESS:
			return action.payload
		default:
			return state
	}
}

export const getters = {
	month: (state: State): string => get(state, 'month', initialState.month),
	day: (state: State): string => get(state, 'day', initialState.day),
	isLoading: (state: State): boolean =>
		get(state, 'isLoading', initialState.isLoading),
	data: (state: State): Workdays => get(state, 'data', initialState.data)
}

export default combineReducers({
	month,
	day,
	isLoading,
	data
})
