import moment from 'moment'
import { AsyncAction } from '~/redux/types'
import { WorkdayResponse, WorkdaysResponse } from '~/services/workdays.service'

import { ActionTypes, Workdays, Actions } from './types'

export const init = (): Actions => {
	const now = moment.utc().startOf('day')
	return {
		type: ActionTypes.INIT,
		payload: {
			month: now.format('Y-M'),
			day: now.format()
		}
	}
}

export const reset = (): Actions => ({
	type: ActionTypes.RESET
})

export const changeMonth = (month: string): Actions => ({
	type: ActionTypes.CHANGE_MONTH,
	payload: month
})

export const changeDay = (day: string): Actions => ({
	type: ActionTypes.CHANGE_DAY,
	payload: day
})

const makeWorkdays = ({
	today,
	firstDay,
	lastDay,
	workdays
}: WorkdaysResponse): Workdays => {
	const data: Workdays = {}

	const loadWorkdays = workdays.reduce<{ [date: string]: WorkdayResponse }>(
		(agg, workday) => ({
			...agg,
			[moment.utc(workday.day).format()]: workday
		}),
		{}
	)

	const momentToday = moment.utc(today)
	const momentLastDay = moment.utc(lastDay)
	const iterator = moment.utc(firstDay)

	while (iterator.isSameOrBefore(momentLastDay)) {
		const formattedDate = iterator.format()

		data[formattedDate] = {
			id: loadWorkdays[formattedDate]?.id || 0,
			day: formattedDate,
			dayOff: loadWorkdays[formattedDate]?.dayOff || false,
			sickLeave: loadWorkdays[formattedDate]?.sickLeave || false,
			absence: loadWorkdays[formattedDate]?.absence || false,
			shifts:
				loadWorkdays[formattedDate]?.shifts.map(s => ({
					id: s.id,
					startedAt: s.startedAt,
					endedAt: s.endedAt,
					workplaceId: s.workplace.id
				})) || [],
			validated: loadWorkdays[formattedDate]?.validated || false,
			disabled:
				iterator.isAfter(momentToday) ||
				loadWorkdays[formattedDate]?.validated ||
				loadWorkdays[formattedDate]?.sickLeave ||
				loadWorkdays[formattedDate]?.absence ||
				false
		}

		iterator.add(1, 'days')
	}

	return data
}

export const workdaysAsync =
	(year: number, month: number): AsyncAction<void> =>
	async (dispatch, getState, { workdays }) => {
		dispatch({ type: ActionTypes.LOAD_START })
		try {
			const payload: WorkdaysResponse = await workdays.get(year, month)

			dispatch({
				type: ActionTypes.LOAD_SUCCESS,
				payload: makeWorkdays(payload)
			})
		} catch (error) {
			dispatch({ type: ActionTypes.LOAD_FAIL, error })
		}
	}
