import classNames from 'classnames'
import React, { useCallback } from 'react'

type Props = {
	activeTab: string
	label: string
	onClick: (tab: string) => void
}

const Tab: React.FC<Props> = ({ activeTab, label, onClick }) => {
	const handleClick: React.MouseEventHandler<HTMLLIElement> =
		useCallback(() => {
			onClick(label)
		}, [onClick, label])

	return (
		<li
			className={classNames(
				'py-2 px-4 border-gray-500 rounded-t-md cursor-pointer',
				{
					'font-bold border border-b-0 ': activeTab === label,
					'border-b': activeTab !== label
				}
			)}
			onClick={handleClick}
		>
			{label}
		</li>
	)
}

export default Tab
