import { combineReducers, Reducer } from 'redux'
import { get } from 'lodash'

import { Actions } from '~/redux/types'

import { ActionTypes, EditableShift, State } from './types'
import moment from 'moment'
import { Time } from '~/utils'

export const initialState: State = {
	id: 0,
	day: '',
	dayOff: false,
	shifts: []
}

const id: Reducer<number, Actions> = (
	state: number = initialState.id,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD:
			return action.payload.id
		default:
			return state
	}
}
const day: Reducer<string, Actions> = (
	state: string = initialState.day,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD:
			return action.payload.day
		default:
			return state
	}
}

const dayOff: Reducer<boolean, Actions> = (
	state: boolean = initialState.dayOff,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD:
			return action.payload.dayOff
		case ActionTypes.CHANGE_DAY_OFF:
			return action.payload
		default:
			return state
	}
}

const shifts: Reducer<EditableShift[], Actions> = (
	state: EditableShift[] = initialState.shifts,
	action: Actions
) => {
	switch (action.type) {
		case ActionTypes.LOAD:
			return action.payload.shifts
		case ActionTypes.CHANGE_WORKPLACE:
			return state.map((value, index) =>
				index !== action.payload.index
					? value
					: { ...value, workplaceId: action.payload.id }
			)
		case ActionTypes.CHANGE_DATE:
			return state.reduce<EditableShift[]>((agg, value, index) => {
				if (index < action.payload.index) {
					agg.push(value)
				} else {
					if (action.payload.type === 'endedAt') {
						if (index === action.payload.index) {
							agg.push({
								...value,
								[action.payload.type]: Time.max(
									action.payload.date,
									value.startedAt
								)
							})
						} else {
							if (
								moment(action.payload.date).utc() <
								moment(value.startedAt)
									.utc()
									.hour(23)
									.minute(30)
							) {
								agg.push({
									...value,
									startedAt: Time.max(
										action.payload.date,
										value.startedAt
									),
									endedAt: Time.max(
										action.payload.date,
										value.endedAt
									)
								})
							}
						}
					} else {
						agg.push({
							...value,
							[action.payload.type]: action.payload.date,
							endedAt: Time.max(
								value.endedAt,
								action.payload.date
							)
						})
					}
				}
				return agg
			}, [])
		case ActionTypes.CREATE_SHIFT:
			return [
				...state,
				{
					...state[state.length - 1],
					id: 0,
					startedAt: state[state.length - 1].endedAt
				}
			]
		case ActionTypes.REMOVE_SHIFT:
			return state.reduce<EditableShift[]>(
				(agg, shift, index) =>
					index !== action.payload.index ? [...agg, shift] : agg,
				[]
			)
		default:
			return state
	}
}

export const getters = {
	id: (state: State): number => get(state, 'id', initialState.id),
	day: (state: State): string => get(state, 'day', initialState.day),
	dayOff: (state: State): boolean =>
		get(state, 'dayOff', initialState.dayOff),
	shifts: (state: State): EditableShift[] =>
		get(state, 'shifts', initialState.shifts)
}

export default combineReducers({
	id,
	day,
	dayOff,
	shifts
})
