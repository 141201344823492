import { get } from 'lodash'
import { createSelector } from 'reselect'

import { Combiner, OPSelector, State as RootState } from '~/redux/types'

import { getters } from './reducer'
import { State, Payment, Payments } from './types'

interface Props {
	date: string
}
const getFromState = (state: RootState): State =>
	get(state, 'payments.validate')
const getFromProps = (_: RootState, { date }: Props) => ({ date })

export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
export const getPayments = (state: RootState): Payments =>
	getters.data(getFromState(state))

export const getPayment = (): OPSelector<
	Props,
	Payment,
	Combiner<Payments, Props, Payment>
> =>
	createSelector(
		[getPayments, getFromProps],
		(payments, { date }) => payments[date]
	)
