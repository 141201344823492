import React, { useEffect } from 'react'

import { useSelector, useDispatch } from '~/hooks'

import {
	actions as schedulesActions,
	selectors as schedulesSelectors
} from '~/redux/schedules'
import { TabContent, Tabs } from '~/components/tab'

const Schedules: React.FC = () => {
	// State
	const schedules = useSelector(schedulesSelectors.getSchedules)

	// Dispatch
	const dispatch = useDispatch()

	// Lifecycle
	useEffect(() => {
		dispatch(schedulesActions.schedulesAsync())
	}, [dispatch])

	return (
		<div className="h-full flex flex-col justify-center">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 border px-2 py-4 transition-all duration-500">
					{Object.keys(schedules).length > 0 && (
						<Tabs>
							{Object.keys(schedules).map(key => (
								<TabContent key={`${key}`} label={key}>
									<img src={schedules[key]} alt={key} />
								</TabContent>
							))}
						</Tabs>
					)}
				</div>
			</div>
		</div>
	)
}

export default Schedules
