import React, { createContext, useCallback, useEffect, useState } from 'react'

export enum Theme {
	DARK = 'dark',
	LIGHT = 'light'
}

const initialTheme = (): Theme => {
	const operatingSystemPreferences = window.matchMedia(
		'(prefers-color-scheme: dark)'
	).matches

	return localStorage.theme === Theme.DARK ||
		(!('theme' in localStorage) && operatingSystemPreferences)
		? Theme.DARK
		: Theme.LIGHT
}

interface ThemeContextValue {
	theme: Theme
	toggle: () => void
}

export const ThemeContext = createContext<ThemeContextValue>({
	theme: Theme.DARK,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	toggle: () => {}
})

export const ThemeProvider: React.FC = ({ children }) => {
	const [theme, setTheme] = useState(initialTheme())

	const toggle = useCallback(() => {
		setTheme(t => (t === Theme.DARK ? Theme.LIGHT : Theme.DARK))
	}, [])

	useEffect(() => {
		const root = window.document.documentElement

		root.classList.remove(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK)
		root.classList.add(theme)

		localStorage.setItem('theme', theme)
	}, [theme])

	return (
		<ThemeContext.Provider value={{ theme, toggle }}>
			{children}
		</ThemeContext.Provider>
	)
}
