export interface Schedules {
	[name: string]: string
}

export type State = {
	isLoading: boolean
	data: Schedules
}

export enum ActionTypes {
	LOAD_START = 'schedules/load_start',
	LOAD_SUCCESS = 'schedules/load_success',
	LOAD_FAIL = 'schedules/load_fail'
}

interface StartAction {
	type: ActionTypes.LOAD_START
}
interface SuccessAction {
	type: ActionTypes.LOAD_SUCCESS
	payload: Schedules
}
interface FailAction {
	type: ActionTypes.LOAD_FAIL
}
export type Actions = StartAction | SuccessAction | FailAction
