import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

interface Props {
	id?: string
	name?: string
	checked: boolean
	onChange: (value: boolean) => void
}

const Switch: React.FC<Props> = ({ id, name, checked, onChange }) => {
	const [value, setValue] = useState<boolean>(checked)

	const handleChange = useCallback(() => setValue(v => !v), [])

	useEffect(() => {
		if (value !== checked) {
			onChange(value)
		}
	}, [onChange, value, checked])

	return (
		<label
			htmlFor={id}
			className={classNames(
				'relative flex justify-start w-full p-1 rounded-full cursor-pointer align-middle select-none transition-all duration-500',
				{
					'bg-gray-400': !value,
					'bg-green-400': value,
					'justify-end': value
				}
			)}
		>
			<div className={classNames('w-full cursor-pointer pr-4')}>
				<input
					type="checkbox"
					name={name}
					id={id}
					className={classNames(
						'block',
						'w-4',
						'h-4',
						'text-white',
						'rounded-full',
						'border-0',
						'appearance-none',
						'cursor-pointer',
						'focus:shadow-none focus:ring-transparent focus:ring-offset-0 focus:ring-offset-transparent',
						'checked:shadow-none checked:ring-transparent checked:ring-offset-0 checked:ring-offset-transparent',
						'transition-all duration-500',
						'ml-0',
						{
							'ml-full': value
						}
					)}
					onChange={handleChange}
					checked={value}
				/>
			</div>
		</label>
	)
}

Switch.defaultProps = {
	id: 'switch',
	name: 'switch-name'
}

export default Switch
