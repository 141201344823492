import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as dotenv from 'dotenv'
import 'moment/locale/pt-br'

import Page from '~/pages'

import './index.css'
import { configureStore, loadState, saveState } from './redux'

dotenv.config()
const store = configureStore(loadState())

store.subscribe(() => {
	saveState(store.getState())
})

dotenv.config()

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Page />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
