import { combineReducers } from 'redux'

import { State } from './types'

import dataReducer, { initialState as dataInitialState } from './data'
import formReducer, { initialState as formInitialState } from './form'

const reducer = combineReducers({
	data: dataReducer,
	form: formReducer
})

const initialState: State = {
	data: dataInitialState,
	form: formInitialState
}

export default reducer

export { reducer, initialState }
