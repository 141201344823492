import axiosClient from './axios-client'

interface WorkplaceResponse {
	id: number
	name: string
}

interface UserResponse {
	id: number
	name: string
	email: string
	workplace: WorkplaceResponse
}

export interface UsersService {
	me: () => Promise<UserResponse>
}

const usersService: UsersService = {
	me: async () =>
		await axiosClient
			.get<UserResponse>('/api/me')
			.then(response => response.data)
}

export default usersService
