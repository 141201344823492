import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from '~/components/switch'
import { useDispatch, useEnsure, useSelector } from '~/hooks'

import { actions as notificationsActions } from '~/redux/notifications'
import { NotificationType } from '~/redux/notifications/types'
import {
	actions as formWorkdaysActions,
	selectors as formWorkdaysSelectors
} from '~/redux/workdays/form'
import {
	actions as workplacesActions,
	selectors as workplacesSelectors
} from '~/redux/workplaces'
import Shift from './Shift'

const Form: React.FC = () => {
	const id = useSelector(formWorkdaysSelectors.getId)
	const day = useSelector(formWorkdaysSelectors.getDay)
	const formattedDay = useSelector(formWorkdaysSelectors.getFormattedDay)
	const shifts = useSelector(formWorkdaysSelectors.getShifts)
	const dayOff = useSelector(formWorkdaysSelectors.getDayOff)
	const lastShiftFilled = useSelector(formWorkdaysSelectors.canCreateShift)

	useEnsure(
		workplacesSelectors.getIsLoading,
		workplacesSelectors.getWorkplaces,
		workplacesActions.workplacesAsync
	)

	// Dispatch
	const dispatch = useDispatch()

	// History
	const history = useHistory()

	// Handlers
	const handleChangeDayOff = useCallback(
		(dayOff: boolean) => {
			dispatch(formWorkdaysActions.changeDayOff(dayOff))
		},
		[dispatch]
	)
	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			if (id) {
				dispatch(formWorkdaysActions.edit(id, dayOff, shifts)).then(
					() => {
						dispatch(
							notificationsActions.add({
								type: NotificationType.SUCCESS,
								message: 'Relatório atualizado com sucesso!'
							})
						)
						history.goBack()
					}
				)
			} else {
				dispatch(formWorkdaysActions.create(day, dayOff, shifts)).then(
					() => {
						dispatch(
							notificationsActions.add({
								type: NotificationType.SUCCESS,
								message: 'Relatório criado com sucesso!'
							})
						)
						history.goBack()
					}
				)
			}
		},
		[dispatch, history, id, day, dayOff, shifts]
	)
	const handleCreateShift = useCallback(
		(event: React.FormEvent<HTMLButtonElement>) => {
			event.preventDefault()
			dispatch(formWorkdaysActions.createShift())
		},
		[dispatch]
	)
	const handleBack = useCallback(
		(event: React.FormEvent<HTMLButtonElement>) => {
			event.preventDefault()
			history.goBack()
		},
		[history]
	)

	return (
		<div className="h-full flex flex-col justify-center">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 border px-2 py-4 transition-all duration-500">
					<form
						className="flex flex-col gap-4"
						onSubmit={handleSubmit}
					>
						<div className="flex justify-between">
							<h1>
								Relatório de horas do dia: <b>{formattedDay}</b>
							</h1>
							<div className="flex justify-between items-center gap-4">
								<label htmlFor="dayOff">Folga:</label>
								<div className="w-16">
									<Switch
										id="dayOff"
										name="dayOff"
										checked={dayOff}
										onChange={handleChangeDayOff}
									/>
								</div>
							</div>
						</div>
						{!dayOff &&
							shifts.map((s, i) => <Shift key={i} index={i} />)}
						<div className="flex justify-between">
							<div>
								<button
									className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
									onClick={handleBack}
								>
									Cancelar
								</button>
							</div>
							<div className="flex justify-end gap-4">
								{!dayOff && lastShiftFilled && (
									<button
										className="px-4 py-2 rounded bg-indigo-500 text-gray-100 hover:bg-indigo-600"
										onClick={handleCreateShift}
									>
										Novo Turno
									</button>
								)}
								<button
									type="submit"
									className="px-4 py-2 rounded bg-green-500 text-gray-100 hover:bg-green-600"
									disabled={!dayOff && shifts.length === 0}
								>
									Enviar
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Form
