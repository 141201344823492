import { get } from 'lodash'
import { createSelector } from 'reselect'

import { State as RootState } from '~/redux/types'

import { getters } from './reducer'
import { State, User } from './types'

const getFromState = (state: RootState): State => get(state, 'user')

export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
const getUserData = (state: RootState): User =>
	getters.data(getFromState(state))

export const getUser = createSelector([getUserData], user =>
	user.id ? user : null
)
