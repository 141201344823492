import axiosClient from './axios-client'

interface WorkplaceResponse {
	id: number
	name: string
}

export interface WorkplacesService {
	getAll: () => Promise<WorkplaceResponse[]>
}

const workplacesService: WorkplacesService = {
	getAll: async () =>
		await axiosClient
			.get<WorkplaceResponse[]>(`/api/workplaces`)
			.then(response => response.data)
}

export default workplacesService
