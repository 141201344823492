export type State = {
	isLoading: boolean
	loggedIn: boolean
}

export enum ActionTypes {
	START = 'auth/start',
	FAIL = 'auth/fail',

	LOGIN = 'auth/login',
	FORGOT_PASSWORD = 'auth/forgot_password',
	RESET_PASSWORD = 'auth/reset_password',
	LOGOUT = 'auth/logout'
}

interface StartAction {
	type: ActionTypes.START
}
interface FailAction {
	type: ActionTypes.FAIL
}
interface ForgotPasswordAction {
	type: ActionTypes.FORGOT_PASSWORD
}
interface ResetPasswordAction {
	type: ActionTypes.RESET_PASSWORD
}
interface LoginAction {
	type: ActionTypes.LOGIN
}
interface LogoutAction {
	type: ActionTypes.LOGOUT
}

export type Actions =
	| LoginAction
	| ForgotPasswordAction
	| ResetPasswordAction
	| LogoutAction
	| StartAction
	| FailAction
