import { get } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import {
	Combiner,
	OPSelector,
	OSelector,
	State as RootState
} from '~/redux/types'
import { Time } from '~/utils'
import { Boundary, TimeOptions } from '~/utils/time'

import { getters } from './reducer'
import { DateTimeType, EditableShift, State } from './types'

interface Props {
	index: number
}
interface BoundaryProps extends Props {
	type: DateTimeType
}

const getFromState = (state: RootState): State => get(state, 'workdays.form')
const getFromProps = (_: RootState, { index }: Props) => ({ index })
const getFromBoundaryProps = (
	_: RootState,
	{ index, type }: BoundaryProps
) => ({
	index,
	type
})

export const getId = (state: RootState): number =>
	getters.id(getFromState(state))
export const getDay = (state: RootState): string =>
	getters.day(getFromState(state))
export const getDayOff = (state: RootState): boolean =>
	getters.dayOff(getFromState(state))
export const getShifts = (state: RootState): EditableShift[] =>
	getters.shifts(getFromState(state))

export const getFormattedDay = (state: RootState): string =>
	moment.utc(getters.day(getFromState(state))).format('D [de] MMMM [de] Y')

export const getShift = (): OPSelector<
	Props,
	EditableShift,
	Combiner<EditableShift[], Props, EditableShift>
> =>
	createSelector(
		[getShifts, getFromProps],
		(shifts, { index }) => shifts[index]
	)
export const canCreateShift = createSelector(
	[getShifts],
	shifts =>
		shifts[shifts.length - 1].startedAt !==
			shifts[shifts.length - 1].endedAt &&
		moment.utc(shifts[shifts.length - 1].endedAt) <
			moment
				.utc(shifts[shifts.length - 1].startedAt)
				.hour(23)
				.minute(30)
)

export const getShiftBoundary = (): OPSelector<
	BoundaryProps,
	Time,
	Combiner<string, EditableShift[], BoundaryProps, Time>
> =>
	createSelector(
		[getDay, getShifts, getFromBoundaryProps],
		(day, shifts, { index, type }) => Time.create(shifts[index][type], day)
	)

interface ShiftBoundariesEdit {
	startedAt: Boundary
	endedAt: Boundary
}

export const getBoundaries: OSelector<
	ShiftBoundariesEdit[],
	Combiner<string, EditableShift[], ShiftBoundariesEdit[]>
> = createSelector([getDay, getShifts], (day, shifts) =>
	shifts.map((shift, index) => ({
		startedAt: {
			min: index
				? Time.create(shifts[index - 1].endedAt, day).setLimit(23).time
				: Time.create(day).setTime(8).time,
			max: Time.create(day).setTime(23, 30).time
		},
		endedAt: {
			min: Time.create(shift.startedAt, day).time,
			max: Time.create(day).setTime(24 + 7).time
		}
	}))
)

export const getBoundary = (): OPSelector<
	BoundaryProps,
	Boundary,
	Combiner<ShiftBoundariesEdit[], BoundaryProps, Boundary>
> =>
	createSelector(
		[getBoundaries, getFromBoundaryProps],
		(boundaries, { index, type }) => boundaries[index][type]
	)

export const getOptions = (): OPSelector<
	BoundaryProps,
	TimeOptions,
	Combiner<ShiftBoundariesEdit[], BoundaryProps, TimeOptions>
> =>
	createSelector(
		[getBoundaries, getFromBoundaryProps],
		(boundaries, { index, type }) =>
			Time.getOptions(
				boundaries[index][type].min,
				boundaries[index][type].max
			)
	)
