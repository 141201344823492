import { get } from 'lodash'

import { State as RootState } from '~/redux/types'

import { getters } from './reducer'
import { State, Notifications } from './types'

const getFromState = (state: RootState): State => get(state, 'notifications')

export const getData = (state: RootState): Notifications =>
	getters.data(getFromState(state))
