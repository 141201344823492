import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import PrivateRoute from '~/components/private-routes'
import ForgotPassword from './forgot-password'
import Form from './form'

import Login from './login'
import ResetPassword from './reset-password'
import TimeSheet from './time-sheet'
import Schedules from './schedules'
import Payments from './payments'

interface Props extends RouteProps {
	loggedIn: boolean
}

const Router: React.FC<Props> = ({ loggedIn }) => (
	<Switch>
		<Route exact path="/">
			{loggedIn ? <Redirect to="/time-sheet" /> : <Login />}
		</Route>
		<Route exact path="/forgot-password">
			{loggedIn ? <Redirect to="/time-sheet" /> : <ForgotPassword />}
		</Route>
		<Route exact path="/reset-password/:token">
			{loggedIn ? <Redirect to="/time-sheet" /> : <ResetPassword />}
		</Route>
		<PrivateRoute exact path="/time-sheet" loggedIn={loggedIn}>
			<TimeSheet />
		</PrivateRoute>
		<PrivateRoute exact path="/workday" loggedIn={loggedIn}>
			<Form />
		</PrivateRoute>
		<PrivateRoute exact path="/schedules" loggedIn={loggedIn}>
			<Schedules />
		</PrivateRoute>
		<PrivateRoute exact path="/payments" loggedIn={loggedIn}>
			<Payments />
		</PrivateRoute>
	</Switch>
)

export default Router
