import axiosClient from './axios-client'

interface SchedulesResponse {
	[name: string]: string
}

export interface SchedulesService {
	getAll: () => Promise<SchedulesResponse>
}

const SchedulesService: SchedulesService = {
	getAll: async () =>
		await axiosClient
			.get<SchedulesResponse>(`/api/schedules`)
			.then(response => response.data)
}

export default SchedulesService
