import { get } from 'lodash'
import moment, { Moment } from 'moment'
import { createSelector } from 'reselect'

import { Combiner, OPSelector, State as RootState } from '~/redux/types'
import { onlyUnique } from '~/utils'

import { getters } from './reducer'
import { Shifts, State, Workday, Workdays } from './types'

interface Props {
	date: string
}
const getFromState = (state: RootState): State => get(state, 'workdays.data')
const getFromProps = (_: RootState, { date }: Props) => ({ date })

export const getMonth = (state: RootState): string =>
	getters.month(getFromState(state))
export const getDay = (state: RootState): moment.Moment =>
	moment.utc(getters.day(getFromState(state)))
export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
export const getWorkdays = (state: RootState): Workdays =>
	getters.data(getFromState(state))

const countHours = (shifts: Shifts): number =>
	shifts.reduce(
		(agg, { endedAt, startedAt }) =>
			agg +
			moment
				.duration(moment.utc(endedAt).diff(moment.utc(startedAt)))
				.asHours(),
		0
	)
interface FormattedWorkday extends Workday {
	hours: number
	workplaces: number[]
}
export const getWorkday = (): OPSelector<
	Props,
	FormattedWorkday,
	Combiner<Workdays, Props, FormattedWorkday>
> =>
	createSelector(
		[getWorkdays, getFromProps],
		(workdays, { date }) =>
			workdays[date] && {
				...workdays[date],
				hours: countHours(workdays[date].shifts),
				workplaces: workdays[date].shifts
					.map(s => s.workplaceId)
					.filter(onlyUnique)
			}
	)

export const getIsSelectedDay = (): OPSelector<
	Props,
	boolean,
	Combiner<Moment, Props, boolean>
> =>
	createSelector([getDay, getFromProps], (day, { date }): boolean =>
		day.isSame(moment.utc(date))
	)

export const getDayInMonth = (): OPSelector<
	Props,
	boolean,
	Combiner<string, Props, boolean>
> =>
	createSelector(
		[getMonth, getFromProps],
		(month, { date }) => month === moment.utc(date).format('Y-M')
	)

export const getFormattedDay = (): OPSelector<
	Props,
	number,
	Combiner<Props, number>
> => createSelector([getFromProps], ({ date }) => moment.utc(date).get('date'))
