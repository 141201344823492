import React, { useEffect, useCallback } from 'react'
import { useDispatch, useEnsure, useSelector } from '~/hooks'

import {
	actions as dataWorkdaysActions,
	selectors as dataWorkdaysSelectors
} from '~/redux/workdays/data'
import { actions as paymentsActions } from '~/redux/payments'
import { actions as formWorkdaysActions } from '~/redux/workdays/form'
import {
	actions as workplacesActions,
	selectors as workplacesSelectors
} from '~/redux/workplaces'

import Calendar from '~/components/calendar'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const TimeSheet: React.FC = () => {
	const month = useSelector(dataWorkdaysSelectors.getMonth)
	const workdays = useSelector(dataWorkdaysSelectors.getWorkdays)

	useEnsure(
		workplacesSelectors.getIsLoading,
		workplacesSelectors.getWorkplaces,
		workplacesActions.workplacesAsync
	)

	// History
	const history = useHistory()

	// Dispatch
	const dispatch = useDispatch()

	// Handlers
	const handleChangeMonth = useCallback(
		(month: string) => {
			dispatch(dataWorkdaysActions.changeMonth(month))
		},
		[dispatch]
	)
	const handleChangeDate = useCallback(
		(date: string) => {
			dispatch(dataWorkdaysActions.changeDay(date))
		},
		[dispatch]
	)
	const handleEditWorkday = useCallback(
		(date: string) => {
			dispatch(formWorkdaysActions.load(date)).then(() => {
				history.push('/workday')
			})
		},
		[dispatch, history]
	)

	useEffect(() => {
		const m = moment(month, 'Y-M')
		if (m.isValid()) {
			dispatch(
				dataWorkdaysActions.workdaysAsync(
					parseInt(m.format('Y')),
					parseInt(m.format('M'))
				)
			)
			dispatch(
				paymentsActions.paymentsAsync(
					parseInt(m.format('Y')),
					parseInt(m.format('M'))
				)
			)
		}
	}, [dispatch, month])

	return (
		<div className="h-full flex flex-col justify-center">
			<div className="container mx-auto md:max-w-3xl p-4">
				<div className="shadow-md bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 border px-2 py-4 transition-all duration-500">
					<Calendar
						month={month}
						workdays={workdays}
						onChangeMonth={handleChangeMonth}
						onChangeDate={handleChangeDate}
						onEdit={handleEditWorkday}
					/>
				</div>
			</div>
		</div>
	)
}

export default TimeSheet
