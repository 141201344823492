import React, { useCallback } from 'react'
import { useDispatch, useReSelector, useSelector } from '~/hooks'

import {
	actions as formWorkdaysActions,
	selectors as formWorkdaysSelectors
} from '~/redux/workdays/form'
import { DateTimeType } from '~/redux/workdays/form/types'
import { selectors as workplacesSelectors } from '~/redux/workplaces'
import TimeSelect from './TimeSelect'

interface Props {
	index: number
}

const Shift: React.FC<Props> = ({ index }) => {
	const { workplaceId } = useReSelector(formWorkdaysSelectors.getShift, {
		index
	})
	const workplaces = useSelector(workplacesSelectors.getWorkplaces)

	// Dispatch
	const dispatch = useDispatch()
	const handleChangeWorkplace: React.ChangeEventHandler<HTMLSelectElement> =
		useCallback(
			event => {
				dispatch(
					formWorkdaysActions.changeWorkplace(
						index,
						parseInt(event.target.value)
					)
				)
			},
			[dispatch, index]
		)
	const handleChangeDate = useCallback(
		(type: DateTimeType, time: string) => {
			dispatch(formWorkdaysActions.changeDate(index, type, time))
		},
		[dispatch, index]
	)
	const handleDelete = useCallback(() => {
		dispatch(formWorkdaysActions.deleteShift(index))
	}, [dispatch, index])

	return (
		<div className="grid grid-cols-3 auto-cols-min gap-2 items-center">
			{index > 0 && (
				<div className="col-span-3 flex justify-end text-red-500 hover:text-red-600">
					<button onClick={handleDelete}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</button>
				</div>
			)}
			<span>Local:</span>
			<label className="col-span-2 flex justify-between items-center gap-2">
				<select
					className="block w-full mt-1 text-black"
					value={workplaceId}
					onChange={handleChangeWorkplace}
				>
					{workplaces.map(w => (
						<option key={w.id} value={w.id}>
							{w.name}
						</option>
					))}
				</select>
			</label>
			<span>Entrada:</span>
			<TimeSelect
				index={index}
				type={DateTimeType.STARTED_AT}
				onChange={handleChangeDate}
			/>
			<span>Saída:</span>
			<TimeSelect
				index={index}
				type={DateTimeType.ENDED_AT}
				onChange={handleChangeDate}
			/>
		</div>
	)
}

export default Shift
