import { AsyncAction } from '../types'
import { ActionTypes } from './types'

interface AuthRequest {
	email: string
	password: string
}
interface ForgotPasswordRequest {
	email: string
}
interface ForgotPasswordResponse {
	message: string
}
interface ResetPasswordRequest {
	token: string
	email: string
	password: string
	passwordConfirmation: string
}
interface ResetPasswordResponse {
	message: string
}

export const login =
	(request: AuthRequest): AsyncAction<void> =>
	async (dispatch, getState, { auth }) => {
		dispatch({ type: ActionTypes.START })
		try {
			await auth.login(request)

			dispatch({ type: ActionTypes.LOGIN })
		} catch (error) {
			dispatch({ type: ActionTypes.FAIL, error })
		}
	}

export const forgotPassword =
	(
		request: ForgotPasswordRequest
	): AsyncAction<ForgotPasswordResponse | undefined> =>
	async (dispatch, getState, { auth }) => {
		dispatch({ type: ActionTypes.START })
		try {
			const result = await auth.forgotPassword(request)

			dispatch({ type: ActionTypes.FORGOT_PASSWORD })
			return result
		} catch (error) {
			dispatch({ type: ActionTypes.FAIL, error })
		}
	}
export const resetPassword =
	(
		request: ResetPasswordRequest
	): AsyncAction<ResetPasswordResponse | undefined> =>
	async (dispatch, getState, { auth }) => {
		dispatch({ type: ActionTypes.START })
		try {
			const result = await auth.resetPassword(request)

			dispatch({ type: ActionTypes.RESET_PASSWORD })
			return result
		} catch (error) {
			dispatch({ type: ActionTypes.FAIL, error })
		}
	}

export const logout =
	(): AsyncAction<void> =>
	async (dispatch, getState, { auth }) => {
		dispatch({ type: ActionTypes.START })
		try {
			await auth.logout()

			dispatch({ type: ActionTypes.LOGOUT })
		} catch (error) {
			dispatch({ type: ActionTypes.FAIL, error })
		}
	}
