import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	withCredentials: true
})

axiosClient.interceptors.response.use((response: AxiosResponse) => {
	if (
		response.data &&
		response.headers['content-type'] === 'application/json'
	) {
		response.data = camelizeKeys(response.data)
	}
	return response
})

axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
	const newConfig = { ...config }

	if (newConfig.headers['Content-Type'] === 'multipart/form-data')
		return newConfig
	if (config.params) {
		newConfig.params = decamelizeKeys(config.params)
	}
	if (config.data) {
		newConfig.data = decamelizeKeys(config.data)
	}
	return newConfig
})

export default axiosClient
