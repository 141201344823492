import React, { useCallback } from 'react'

import { useReSelector } from '~/hooks'

import { selectors as formWorkdaysSelectors } from '~/redux/workdays/form'
import { DateTimeType } from '~/redux/workdays/form/types'

interface Props {
	type: DateTimeType
	index: number
	onChange: (type: DateTimeType, time: string) => void
}

const TimeSelect: React.FC<Props> = ({ type, index, onChange }) => {
	const options = useReSelector(formWorkdaysSelectors.getOptions, {
		type,
		index
	})
	const value = useReSelector(formWorkdaysSelectors.getShiftBoundary, {
		type,
		index
	})

	const handleChange: React.ChangeEventHandler<HTMLSelectElement> =
		useCallback(
			event => {
				const [name, type] = event.target.name.split('.')
				const valueToSet = parseInt(event.target.value)
				switch (type) {
					case 'hour':
						onChange(
							name as DateTimeType,
							value.setHour(valueToSet).time
						)
						break
					case 'minute':
						onChange(
							name as DateTimeType,
							value.setMinute(valueToSet).time
						)
						break
					default:
						break
				}
			},
			[onChange, value]
		)
	return (
		<div className="col-span-2 flex justify-between items-center gap-2">
			<select
				name={`${type}.hour`}
				className="block w-full mt-1 text-black"
				value={value.timeObject.hour}
				onChange={handleChange}
			>
				{Object.keys(options).map((hour, index) => (
					<option key={`${type}-hour-${index}`} value={hour}>
						{parseInt(hour) % 24}
					</option>
				))}
			</select>
			<span>H</span>
			<select
				name={`${type}.minute`}
				className="block w-full mt-1 text-black"
				value={value.timeObject.minute}
				onChange={handleChange}
			>
				{options[value.timeObject.hour]?.map(time => (
					<option
						key={`${type}-minute-${time.minute}`}
						value={time.minute}
					>
						{String(time.minute).padStart(2, '0')}
					</option>
				))}
			</select>
			<span>m</span>
		</div>
	)
}

export default TimeSelect
