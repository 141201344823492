import axiosClient from './axios-client'

interface PaymentResponse {
	id: number
	day: string
	amount: number
}

type PaymentsResponse = PaymentResponse[]

export interface PaymentsService {
	get: (year: number, month: number) => Promise<PaymentsResponse>
	getNonValidate: () => Promise<PaymentsResponse>
	validate: (id: number) => Promise<PaymentsResponse>
}

const workdaysService: PaymentsService = {
	get: async (year: number, month: number) =>
		await axiosClient
			.get<PaymentsResponse>(`/api/payments/year/${year}/month/${month}`)
			.then(response => response.data),
	getNonValidate: async () =>
		await axiosClient
			.get<PaymentsResponse>(`/api/payments`)
			.then(response => response.data),
	validate: async (id: number) =>
		await axiosClient
			.post<PaymentsResponse>(`/api/payments/${id}/validate`)
			.then(response => response.data)
}

export default workdaysService
