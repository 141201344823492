import moment from 'moment'

import { Actions, AsyncAction } from '~/redux/types'

import { selectors as userSelectors } from '~/redux/user'
import { selectors as workdaysDataSelectors } from '../data'
import { ActionTypes, DateTimeType, EditableShift } from './types'

export const load =
	(date: string): AsyncAction<void> =>
	async (dispatch, getState) => {
		const state = getState()
		const workday = workdaysDataSelectors.getWorkday()(state, { date })
		const user = userSelectors.getUser(state)

		const minDate = moment.utc(date).hour(9).minute(0).format()
		const shifts = [
			{
				workplaceId: user?.workplaceId || 0,
				startedAt: minDate,
				endedAt: minDate
			}
		]

		dispatch({
			type: ActionTypes.LOAD,
			payload: {
				id: workday.id || 0,
				day: date,
				dayOff: workday.dayOff || false,
				shifts: workday.shifts.length ? workday.shifts : shifts
			}
		})
	}

export const changeDayOff = (dayOff: boolean): Actions => ({
	type: ActionTypes.CHANGE_DAY_OFF,
	payload: dayOff
})

export const changeWorkplace = (index: number, id: number): Actions => ({
	type: ActionTypes.CHANGE_WORKPLACE,
	payload: {
		index,
		id
	}
})

export const changeDate = (
	index: number,
	type: DateTimeType,
	date: string
): Actions => ({
	type: ActionTypes.CHANGE_DATE,
	payload: {
		index,
		type,
		date
	}
})

export const createShift = (): Actions => ({
	type: ActionTypes.CREATE_SHIFT
})

export const deleteShift = (index: number): Actions => ({
	type: ActionTypes.REMOVE_SHIFT,
	payload: {
		index
	}
})

export const create =
	(
		day: string,
		dayOff: boolean,
		shifts: EditableShift[]
	): AsyncAction<void> =>
	async (dispatch, getState, { workdays }) => {
		try {
			dispatch({ type: ActionTypes.SUBMIT_START })
			await workdays.post({
				day,
				dayOff,
				shifts: dayOff
					? []
					: shifts.filter(s => s.startedAt !== s.endedAt)
			})
			dispatch({ type: ActionTypes.SUBMIT_SUCCESS })
		} catch (error) {
			dispatch({ type: ActionTypes.SUBMIT_FAIL, error })
		}
	}
export const edit =
	(id: number, dayOff: boolean, shifts: EditableShift[]): AsyncAction<void> =>
	async (dispatch, getState, { workdays }) => {
		try {
			dispatch({ type: ActionTypes.SUBMIT_START })
			await workdays.put({
				id,
				dayOff,
				shifts: dayOff
					? []
					: shifts.filter(s => s.startedAt !== s.endedAt)
			})
			dispatch({ type: ActionTypes.SUBMIT_SUCCESS })
		} catch (error) {
			dispatch({ type: ActionTypes.SUBMIT_FAIL, error })
		}
	}
