import { get } from 'lodash'
import { createSelector } from 'reselect'

import { notEmpty } from '~/utils'

import { Combiner, OPSelector, State as RootState } from '../types'

import { State, Workplace } from './types'
import { getters } from './reducer'

interface Props {
	ids: number[]
}
const getFromState = (state: RootState): State => get(state, 'workplaces')
const getFromProps = (_: RootState, { ids }: Props) => ({ ids })

export const getIsLoading = (state: RootState): boolean =>
	getters.isLoading(getFromState(state))
export const getWorkplaces = (state: RootState): Workplace[] =>
	getters.data(getFromState(state))

export const getWorkplaceName = (): OPSelector<
	Props,
	Workplace[],
	Combiner<Workplace[], Props, Workplace[]>
> =>
	createSelector([getWorkplaces, getFromProps], (workplaces, { ids }) =>
		ids.map(id => workplaces.find(w => w.id === id)).filter(notEmpty)
	)
