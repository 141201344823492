import { Middleware } from 'redux'
import { ActionTypes, NotificationType } from './types'

const notificationMiddleware: Middleware =
	({ dispatch }) =>
	next =>
	action => {
		if (
			!action.error ||
			!action.error.response ||
			!action.error.response.data
		) {
			return next(action)
		}
		const { data } = action.error.response
		if (data.errors) {
			const errorsByField = Object.values(data.errors) as string[][]
			errorsByField.forEach((errors: string[]) => {
				errors.forEach((message: string) => {
					dispatch({
						type: ActionTypes.ADD,
						payload: {
							type: NotificationType.ERROR,
							message
						}
					})
				})
			})
		}
		if (data.message) {
			dispatch({
				type: ActionTypes.ADD,
				payload: {
					type: NotificationType.ERROR,
					message: action.error.response.data.message
				}
			})
		}
		return next(action)
	}

export default notificationMiddleware
