import React, { ReactElement, useState, ReactNode, useCallback } from 'react'

import Tab from './Tab'

type ChildProps = {
	label: string
	children: ReactNode
}

type Props = {
	children: ReactElement<ChildProps>[]
}

const Tabs: React.FC<Props> = ({ children }) => {
	const [activeTab, setActiveTab] = useState(children[0].props.label)

	const handleClickTabItem = useCallback((tab: string) => {
		setActiveTab(tab)
	}, [])

	return (
		<div className="tabs">
			<ol className="flex items-end">
				{children.map(child => {
					const { label } = child.props

					return (
						<Tab
							activeTab={activeTab}
							key={label}
							label={label}
							onClick={handleClickTabItem}
						/>
					)
				})}
				<span className="flex-grow border-t border-gray-500"></span>
			</ol>
			<div className="p-2 border border-t-0 border-gray-500 ">
				{children.map(child => {
					if (child.props.label !== activeTab) return undefined
					return child.props.children
				})}
			</div>
		</div>
	)
}

export default Tabs
