import classNames from 'classnames'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from '~/hooks'

import { actions as notificationsActions } from '~/redux/notifications'

export enum NotificationType {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error'
}

interface Props {
	id: string
	type: NotificationType
	title?: string
	message: string
}

const Notification: React.FC<Props> = ({ id, type, message }) => {
	const [percentage, setPercentage] = useState(0)
	const [pInterval, setPInterval] = useState<NodeJS.Timeout>()

	// Dispatch
	const dispatch = useDispatch()

	const progress = useCallback(() => {
		setPInterval(
			setInterval(() => {
				setPercentage(p => Math.min(p + 75 / 5000, 1))
			}, 75)
		)
	}, [])

	useEffect(progress, [progress])
	useEffect(() => {
		if (percentage >= 1) {
			pInterval && clearInterval(pInterval)
			setTimeout(() => {
				dispatch(notificationsActions.remove(id))
			}, 75)
		}
	}, [dispatch, id, percentage, pInterval])

	const handleHover = useCallback(() => {
		pInterval && clearInterval(pInterval)
	}, [pInterval])

	return (
		<div
			onMouseEnter={handleHover}
			onMouseLeave={progress}
			className={classNames(
				'shadow-lg text-gray-100 p-3 w-72 relative rounded-sm',
				{
					'bg-green-600': type === NotificationType.SUCCESS,
					'bg-red-600': type === NotificationType.ERROR
				}
			)}
		>
			{message}
			<span
				className={classNames('absolute bottom-0 h-1 left-0 right-0', {
					'bg-green-500': type === NotificationType.SUCCESS,
					'bg-red-500': type === NotificationType.ERROR
				})}
			>
				<div
					style={{ width: `${percentage * 100}%` }}
					className={classNames(
						'transition-all ease-linear duration-75 h-full bg-gray-100 relative w-0'
					)}
				></div>
			</span>
		</div>
	)
}

export default Notification
